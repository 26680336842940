<template>
  <div class="mf-login">
    <div class="mf-login__left">
      <div class="mf-login__left__logo">
        <img src="@/assets/login_logo.png" />
      </div>
      <div class="mf-login__left__person">
        <img src="@/assets/login_person.png" />
      </div>
    </div>
    <div class="mf-login__right">
      <div class="mf-login__right__form">
        <div class="mf-login__right__form__header">
          OAuth2 认证中心
        </div>
        <div class="mf-login__right__form__body">
          <el-form>
            <el-form-item label="用户名">
              <el-input v-model="userName" />
            </el-form-item>
            <el-form-item label="密码">
              <el-input v-model="password" show-password @keydown.native.enter="login" />
            </el-form-item>
            <el-form-item label="">
              <el-button :loading="isLogin" type="primary" @click.stop="login" style="width: 100%;margin-top: 8px;">登录</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bMd5 from 'blueimp-md5';

export default {
  data() {
    return {
      isLogin: false,
      userName: '',
      password: ''
    }
  },
  methods: {
    checkForm() {
      if (!this.userName) {
        return '请填写用户名'
      }
      if (!this.password) {
        return '请填写密码'
      }
    },
    login() {
      if (this.isLogin) {
        return
      }

      const formValidate = this.checkForm()

      if (formValidate) {
        this.$message.error(formValidate)
        return
      }

      this.isLogin = true

      this.$http({
        url: '/login',
        method: 'POST',
        params: {
          name: this.userName,
          pwd: this.getEncryptPassword(this.password)
        }
      }).then(res => {
        console.error(res)
        this.$eventBus.$emit('startAuthorize')
      }).finally(() => {
        this.isLogin = false
      })
    },
    getEncryptPassword(password) {
      return bMd5(password + 'SEED')
    }
  }
}
</script>

<style lang="scss">
$leftWidth: 540px;
.mf-login{
  width: 100vw;
  height: 100vh;
  display: flex;
  position: relative;
  .mf-login__left{
    width: $leftWidth;
    background: #D6E1FF;
    .mf-login__left__logo{
      width: 120px;
      position: absolute;
      left: 60px;
      top: 40px;
      img{
        max-width: 100%;
      }
    }
    .mf-login__left__person{
      position: absolute;
      bottom: 0;
      left: 0;
      img {
        max-width: $leftWidth;
      }
    }
  }
  .mf-login__right{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    .mf-login__right__form{
      width: 400px;
      .mf-login__right__form__header{
        font-size: 32px;
        color: #344563;
        font-weight: bold;
      }
      .mf-login__right__form__body{
        margin-top: 32px;
        .el-form-item__label{
          line-height: 28px;
          font-size: 12px;
          color: #57565B;
          font-weight: bold;
        }
      }
    }
  }

}
</style>
