import Vue from 'vue'
import App from './App.vue'
import httpPlugin from '@/plugin/http'
import globalEventBusPlugin from '@/plugin/globalEventBus'

Vue.config.productionTip = false

import ElementUI from 'element-ui';
import './assets/element-variables.scss'

Vue.use(ElementUI)
Vue.use(httpPlugin)
Vue.use(globalEventBusPlugin)

new Vue({
  render: h => h(App),
}).$mount('#app')
