<template>
  <div>
    <el-dialog title="用户授权" :visible.sync="dialogVisible" center width="360px">
      <div class="x-authorize">
        <div class="x-authorize__header">
          申请使用 SEED 以下相关权限
        </div>
        <div class="x-authorize__body">
          <el-checkbox-group v-model="checkedList">
            <div class="x-authorize__body__item" v-for="(item, itemIndex) of scopeItemList" :key="itemIndex">
              <el-checkbox :label="item.value">{{ item.label }}</el-checkbox>
            </div>
          </el-checkbox-group>
        </div>
        <div class="x-authorize__bottom">
          <el-button type="info" plain style="margin-right: 16px" @click.stop="closeDialog">拒绝</el-button>
          <el-button :loading="isUpdateScope" @click.stop="updateUserScope" type="primary" :disabled="checkedList.length === 0">确认授权</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
const queryString = require('query-string')

export default {
  data() {
    return {
      isUpdateScope: false,
      checkedList: [],
      scopeItemList: [],
      dialogVisible: false
    }
  },
  mounted() {
    this.$eventBus.$on('startAuthorize', this.startAuthorize)
  },
  beforeDestroy() {
    this.$eventBus.$off('startAuthorize', this.startAuthorize)
  },
  methods: {
    closeDialog() {
      this.dialogVisible = false
    },
    startAuthorize() {
      console.error('start')
      this.getScopeList()
    },
    updateUserScope() { // 更新用户权限
      if (this.isUpdateScope) {
        return
      }

      this.isUpdateScope = true

      const parsed = queryString.parse(location.href)

      const clientId = parsed.client_id

      this.$http({
        url: '/confirm',
        params: {
          client_id: clientId,
          scope: this.checkedList.join(',')
        }
      }).then(() => {
        this.dialogVisible = false
        this.$message.success('授权成功，正在进行页面跳转')

        setTimeout(() => {
          this.$eventBus.$emit('redirect')
        }, 1500)
      }).finally(() => {
        this.isUpdateScope = false
      })
    },
    getScopeList() {
      this.$http({
        url: '/scope'
      }).then(res => {
        const scopeItemList = []
        const checkedList = []

        res.unauthorizedScopes.forEach(item => {
          scopeItemList.push({
            label: item.description,
            value: item.scopeName,
          })
        })

        res.userScopes.forEach(item => {
          scopeItemList.push({
            label: item.description,
            value: item.scopeName,
          })
          checkedList.push(item.scopeName)
        })

        if (res.userScopes.length === 0) { // 默认全选
          res.unauthorizedScopes.forEach(item => {
            checkedList.push(item.scopeName)
          })
        }

        this.checkedList = checkedList
        this.scopeItemList = scopeItemList

        this.dialogVisible = true
      })
    }
  }
}
</script>

<style lang="scss">
.x-authorize{
  .x-authorize__header{
    color: #57565B;
    font-size: 14px;
  }
  .x-authorize__body{
    margin-top: 8px;
    margin-left: 16px;
    .x-authorize__body__item{
      margin-bottom: 8px;
    }
  }
  .x-authorize__bottom{
    margin-top: 32px;
    text-align: center;
  }
}
</style>
