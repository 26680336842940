<script>
export default {
  mounted() {
    this.$eventBus.$on('redirect', this.handleRedirect)
  },
  beforeDestroy() {
    this.$eventBus.$off('redirect', this.handleRedirect)
  },
  render() {
    return ''
  },
  methods: {
    handleRedirect() {
      window.location.reload(true)
    }
  }
}
</script>
