import axios from 'axios'
import { Message } from 'element-ui';

const instance = axios.create({
	baseURL: process.env.VUE_APP_API_BASE_PATH,
	timeout: 1000,
	headers: {'X-Custom-Header': 'foobar'}
});

// Add a response interceptor
instance.interceptors.response.use(function (response) {
	const data = response.data
	
	if (data.code !== 200) {
		if (data.msg) {
			Message.error(data.msg)
		}
		
		return Promise.reject(response)
	}
	return data.data;
}, function (error) {
	return Promise.reject(error);
});

export default {
	install(Vue) {
		Vue.prototype.$http = instance
	}
	
}
