<template>
  <div id="app">
    <x-main />
  </div>
</template>

<script>
import xMain from './views/main'

export default {
  name: 'App',
  components: {
    xMain
  }
}
</script>

<style>
html, body{
  padding: 0;
  margin: 0;
}
#app {

}
</style>
