<template>
  <div>
    <div>
      <x-login />
    </div>
    <div>
      <x-authorize />
    </div>
    <div>
      <x-redirect />
    </div>
  </div>
</template>



<script>
import xLogin from './login'
import xAuthorize from './authorize'
import xRedirect from './redirect'

export default {
  components: {
    xLogin,
    xAuthorize,
    xRedirect,
  }
}
</script>
